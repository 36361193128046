import { Media } from '@raylink-overseas/common/theme'
import React from 'react'
import styled from 'styled-components'

type Props = {
  activeAnchor: string
}

const AnchorWrapper = styled.div`
  width: 232px;
  height: 276px;
  border-radius: 0px 16px 16px 0px;
  position: fixed;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-radius: 0 16px 16px 0;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(269.29deg, #ffffff 0.54%, #fdfeff 99.34%);
  left: 0;
  top: calc(50% - 138px);
  padding: 1px 1px 1px 0;
  ${Media.ipad`
    display: none;
  `}
  z-index: 100
`

const AnchorContent = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0px 16px 16px 0px;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    180deg,
    #b6c1ff 0%,
    rgba(171, 183, 255, 0.6) 100%
  );
`

const AnchorItem = styled.div<{ isActive: boolean }>`
  width: 192px;
  height: 48px;
  padding: 8px 22px;
  border-radius: 8px;
  background-color: #fff;
  line-height: 32px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.6)};
  border: ${({ isActive }) => (isActive ? '1px solid #4961F4' : 'none')};
  box-shadow: ${({ isActive }) =>
    isActive ? '0px 3.2px 3.2px 0px #B3BEFF66' : 'none'};
  cursor: pointer;
  a {
    color: #1f2329;
    display: block;
    font-size: 18px;
    span {
      font-weight: 500;
    }
  }
`

export const anchorList = [
  { desc: 'Q&A Game', sort: '01  ', link: '#q&a', id: 'q&a' },
  { desc: '15% Discount', sort: '02  ', link: '#pay', id: 'pay' },
  { desc: 'Lucky Bonus', sort: '03  ', link: '#email', id: 'email' },
]

const Anchor: React.FC<Props> = props => {
  const { activeAnchor } = props
  return (
    <AnchorWrapper>
      <AnchorContent>
        {anchorList.map((item, index) => (
          <AnchorItem key={index} isActive={activeAnchor === item.id}>
            <a href={item.link}>
              <span>{item.sort}</span>
              {item.desc}
            </a>
          </AnchorItem>
        ))}
      </AnchorContent>
    </AnchorWrapper>
  )
}

export default Anchor
