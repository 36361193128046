import React, { useEffect, useState } from 'react'

import {
  CountDownDescription,
  CountDownTimeEnd,
  CountDownTimeItem,
  CountDownTimeWrapper,
  CountDownTitle,
  CountDownWrapper,
} from './atoms'

const timeZone = new Date().getTimezoneOffset() / 60

const initTimeLeft = {
  Days: 0,
  Hours: 0,
  Minutes: 0,
  Seconds: 0,
}

export const campaignEndTime = new Date(
  new Date('2024-08-12 00:00').valueOf() - timeZone * 60 * 60 * 1000
)

const CountDown = () => {
  const calculateTimeLeft = () => {
    const difference = +campaignEndTime - +new Date()

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
      const minutes = Math.floor((difference / 1000 / 60) % 60)
      const seconds = Math.floor((difference / 1000) % 60)
      return {
        Days: days >= 10 ? days : `0${days}`,
        Hours: hours >= 10 ? hours : `0${hours}`,
        Minutes: minutes >= 10 ? minutes : `0${minutes}`,
        Seconds: seconds >= 10 ? seconds : `0${seconds}`,
      }
    }
    return initTimeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  return (
    <CountDownWrapper>
      <div className="connect-better">#AvicaConnectsBetter</div>
      <CountDownTitle>Better Connecting the World</CountDownTitle>
      <CountDownDescription>
        Paris 2024 Olympics unite nations, while Avica Remote Desktop connects
        everyone! Join our campaign, get 15% OFF on Avica Pro annual license,
        and get a chance to win a $50 Amazon gift card!
      </CountDownDescription>
      <CountDownTimeEnd>Campaign Ends In</CountDownTimeEnd>

      <CountDownTimeWrapper>
        {Object.keys(timeLeft).map(time => (
          <CountDownTimeItem key={time}>
            <div className="count-wrapper">
              <p className="count">{timeLeft[time as keyof typeof timeLeft]}</p>
            </div>
            <p className="date">{time}</p>
          </CountDownTimeItem>
        ))}
      </CountDownTimeWrapper>
      <div className="amazon" />
      <div className="connect-logo" />
      <div className="license" />
      <div className="connect-better-bottom">
        <a href="#q&a">
          <div className="arrow-down">
            <div className="arrow-down_white_one" />
            <div className="arrow-down_white_two" />
          </div>
        </a>
      </div>
    </CountDownWrapper>
  )
}

export default CountDown
