import styled from 'styled-components'
import PromoCampaginBackgroud from '../../images/promo-compagin/promo-compagin_backgroud.png'
import PromoCampaginBackgroudIpad from '../../images/promo-compagin/promo-compagin_backgroud_ipad.png'
import PromoCampaginBackgroudPhone from '../../images/promo-compagin/promo-compagin_backgroud_phone.png'
import { Media } from '@raylink-overseas/common/theme'
import RunningManBg from '../../images/promo-compagin/running-man.png'
import RunningManBgIpad from '../../images/promo-compagin/running-man_ipad.png'

export const PromoCampaginWrapper = styled.div`
  position: relative;
  width: 100%;
  background: url(${PromoCampaginBackgroud}) no-repeat top, #c1e6ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  .volleyball {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  ${Media.ipad`
    background: url(${PromoCampaginBackgroudIpad}) no-repeat top, #c1e6ff;
    .volleyball {
      display: none
    }
  `}
  ${Media.phone`
    margin-top: 0.76rem;
    background: url(${PromoCampaginBackgroudPhone}) no-repeat top, #c1e6ff;
    background-size: 100% 100%;
  `}
`

export const RunningManWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 656px;
  ${Media.ipad`
    top: 580px;
  `}
  ${Media.phone`
    display: none;
  `};
`

export const RunningMan = styled.div`
  width: 194px;
  height: 220px;
  margin-left: 632px;
  background: url(${RunningManBg}) center / 100% 100% no-repeat;
  ${Media.ipad`
    margin-left: 191px;
    background: url(${RunningManBgIpad}) center / 100% 100% no-repeat;
  `}
`
