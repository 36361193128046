import React from 'react'
import styled from 'styled-components'
import AmericanExpress from '../../../../images/pricing/american-express.svg'
import Discover from '../../../../images/pricing/discover.svg'
import Mastercard from '../../../../images/pricing/mastercard.svg'
import UnionPay from '../../../../images/pricing/union-pay.svg'
import Visa from '../../../../images/pricing/visa.svg'
import DinersClub from '../../../../images/pricing/diners-club.svg'
import JCBImg from '../../../../images/pricing/JCB.svg'
import { CreditCardWrapper } from '../../../pricing/components/credit-card'
import { Media } from '@raylink-overseas/common/theme'

const CreditCardProWrapper = styled(CreditCardWrapper)`
  position: absolute;
  margin: 0px;
  z-index: 2;
  bottom: 82px;
  p {
    margin: 0;
    font-size: 16px;
    color: #646a73;
    line-height: 40px;
  }
  ${Media.ipad`
    bottom:90px
  `}
  ${Media.phone`
    bottom: 0.8rem;
    .img-container {
        display:block;
        width: 5.86rem;
        padding: 0;
        p{
          font-size: 0.32rem;
          height: 0.4rem;
          line-height: 0.4rem;
        }
        img {
          width:0.8rem;
          height: 0.6rem;
        }
        img:not(:last-child) {
          margin-right: 0.04rem;
        }
      }
  `}
`

const CreditCard = () => {
  return (
    <CreditCardProWrapper>
      <div className="img-container">
        <p>We Accept</p>
        {[
          Visa,
          Mastercard,
          AmericanExpress,
          JCBImg,
          UnionPay,
          Discover,
          DinersClub,
        ].map((src, idx) => (
          <img src={src} key={idx} alt="" />
        ))}
      </div>
    </CreditCardProWrapper>
  )
}
export default CreditCard
