import React, { useState } from 'react'
import { CampaginTextarea } from '../question/atom'
import { Button } from '@raylink-overseas/common/components'

import { FloatDialog } from '../../../pricing/components/float-dialog'
import Submitted from '../submitted'
import { API, Axios } from '@raylink-overseas/common/api'

import CryptoJS from 'crypto-js'
import {
  EamilCardWrapper,
  EmailCardLabel,
  EmailCardTContent,
  EmailContentWrapper,
} from './atom'

const Email = () => {
  const http = new Axios(API.host)

  const [checked, setChecked] = useState(true)

  const [showSubmit, setShowSubmit] = useState(false)

  const [email, setEmail] = useState('')

  const handleEnter = async () => {
    const date = await http.get(API.getDateTime)

    if (date?.data) {
      const marketing = checked ? 0 : 1

      const md5Info = `date=${date.data}&marketing=${marketing}&raylink6`

      const params = {
        email,
        marketing,
        date: date.data,
        md5: CryptoJS.MD5(md5Info).toString(),
      }

      const res = await http.post(API.collectUserEmail, params)
      if (res?.code === 200) {
        setShowSubmit(true)
      }
    }
  }

  return (
    <EamilCardWrapper id="email">
      <EmailCardLabel>
        <p />
      </EmailCardLabel>
      <EmailCardTContent>
        <p>Join Lucky Prize Draw</p>
      </EmailCardTContent>
      <div className="shop" />
      <EmailContentWrapper>
        <div className="description">
          <p>
            Enter your email now for a chance to win a
            <span> $50 Amazon gift card</span>.
          </p>
          <p>
            <span>20 lucky winners</span> will be randomly chosen from Avica Pro
            members who have shared their
            <p className="email-address">email address.</p>
          </p>
        </div>
        <div className="email">
          <CampaginTextarea
            placeholder="Email"
            value={email}
            onChange={e => {
              setEmail(e.target.value)
            }}
          />
          <div className="tip-wrapper">
            <FloatDialog className="tip">
              Rest assured that your email address will be kept confidential.
            </FloatDialog>
          </div>
        </div>
        <div className="confirm">
          <input
            type="checkbox"
            className="checkbox"
            checked={checked}
            onChange={e => {
              setChecked(e.target.checked)
            }}
          />
          <span>I would like to receive future emails from Avica</span>
        </div>
        <Button className="enter" onClick={handleEnter}>
          Enter to Win
        </Button>
        <div className="link">
          <p>Follow us for giveaway results:</p>
          <div className="img-warpper">
            <a
              href="https://www.facebook.com/AvicaSoftware"
              target="_blank"
              rel="noreferrer"
            >
              <span className="facebook" />
            </a>
            <a
              href="https://www.linkedin.com/company/avica-software/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="linkedin" />
            </a>
            <a
              href="https://discord.gg/QDEfMg93XS"
              target="_blank"
              rel="noreferrer"
            >
              <span className="discord" />
            </a>
          </div>
        </div>
      </EmailContentWrapper>
      {showSubmit && (
        <Submitted
          description={`Your submission has been received. Winners will be announced on Avica's official Facebook, Linkedin, and Discord. Wish you the best of luck!`}
          buttonText="OK"
          clickCallback={() => {
            setShowSubmit(false)
          }}
        />
      )}
    </EamilCardWrapper>
  )
}

export default Email
