import { Media } from '@raylink-overseas/common/theme'
import React from 'react'
import styled from 'styled-components'

const ConditionsWrapper = styled.div`
  position: relative;
  border-radius: 30px;
  box-shadow: 0px 14px 15.2px 0px #88c8f133;
  background: linear-gradient(
    180deg,
    rgba(173, 199, 255, 0.8) -7.64%,
    rgba(238, 243, 255, 0.8) 15.88%,
    rgba(248, 251, 255, 0.8) 100%
  );
  width: 1200px;
  height: 558px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    background: linear-gradient(290.07deg, #445fff 53.77%, #55bcee 97.08%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 40px;
  }
  .condition-item {
    width: 973px;
    font-size: 20px;
    color: #666666;
    display: flex;
    margin-bottom: 20px;
    p:nth-of-type(1) {
      margin-right: 6px;
    }
  }
  img {
    position: absolute;
  }
  ${Media.ipad`
    width: 895px;
    height: 709px;
    .condition-item{
      width: 738px
    }
    img{
      display: none;
    }
  `}
  ${Media.phone`
    width: 6.86rem;
    height: 18.74rem;
    border-radius: 0.2rem;
    margin-bottom: 0.48rem;
    h2{
      font-size: 0.4rem;
      margin-bottom: 0.36rem;
    }
    .condition-item{
      font-size: 0.32rem;
      width: 6rem;
      line-height: 0.6rem;
      margin-bottom: 0;
    }
  `}
`

const conditions = [
  'The campaign starts on July 26th, 2024, at 0:00 (UTC+8) and runs until August 11th, 2024, at 24:00 (UTC+8).',
  'Upon answering the questions in the Part 1 campaign, participants will get a 15% discount on an annual license of Avica Pro.',
  'Avica Pro members can share their email address to join the Part 3 campaign. We will randomly select 20 lucky winners from them and each winner will receive a $50 Amazon Gift Card.',
  `We will announce the giveaway results on Avica's official Facebook, Linkedin, and Discord. The 20 winners will receive an email from the Avica Team containing their prize.`,
  `We take the privacy of our participants' personal information seriously and follow our privacy policy. If you have any questions, please contact us at mkt@avica.link.`,
  'Copyright © Avica Remote Desktop. All rights reserved.',
]

const Conditions = () => {
  return (
    <ConditionsWrapper>
      <h2>Terms and Conditions</h2>
      {conditions.map((condition, index) => (
        <div key={index} className="condition-item">
          <p>{index + 1}.</p> <p>{condition}</p>
        </div>
      ))}
    </ConditionsWrapper>
  )
}

export default Conditions
