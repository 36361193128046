import cardBg from '../../../../images/promo-compagin/card-bg.png'
import cardBgIpad from '../../../../images/promo-compagin/card-bg_ipad.png'
import cardBgPhone from '../../../../images/promo-compagin/card-bg_phone.png'
import styled from 'styled-components'
import { Button } from '../../../../view/pricing/components/price-card/atoms'
import { Media } from '@raylink-overseas/common/theme'
import question from '../../../../images/promo-compagin/q&a.svg'
import questionIpad from '../../../../images/promo-compagin/q&a_ipad.svg'
import questionPhone from '../../../../images/promo-compagin/q&a_phone.svg'
import pay from '../../../../images/promo-compagin/pay.svg'
import payPhone from '../../../../images/promo-compagin/pay_phone.svg'
import shop from '../../../../images/promo-compagin/shop.svg'
import shopPhone from '../../../../images/promo-compagin/shop_phone.svg'
import part1 from '../../../../images/promo-compagin/part1.svg'
import part1Ipad from '../../../../images/promo-compagin/part1_ipad.svg'
import part1Phone from '../../../../images/promo-compagin/part1_phone.svg'

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1234px;
  height: 692px;
  position: relative;
  margin-bottom: 80px;
  .question {
    position: absolute;
    top: 20px;
    right: -76px;
    z-index: 2;
    width: 623px;
    height: 596px;
    background: url(${question}) no-repeat;
  }
  .previous,
  .next {
    position: absolute;
    bottom: 97px;
    z-index: 2;
    width: 188px;
    height: 44px;
  }
  .previous {
    right: 382px;
    img {
      margin-right: 16px;
    }
  }
  .next {
    right: 128px;
    img {
      margin-left: 16px;
    }
  }
  .pay {
    position: absolute;
    left: -150px;
    top: 144px;
    width: 593px;
    height: 586px;
    z-index: 2;
    background: url(${pay}) no-repeat;
  }
  .shop {
    position: absolute;
    right: -186px;
    top: 126px;
    z-index: 2;
    background: url(${shop}) no-repeat;
    width: 637px;
    height: 620px;
  }
  ${Media.ipad`
    width: 929px;
    .previous,
    .next{
      bottom:70px;
    }
    .previous{
      right: 338px
    }
    .next{
      right:92px
    }
    .question{
      width: 465px;
      height: 477px;
      top:10px;
      right:-24px;
      background: url(${questionIpad}) no-repeat;
    }
  `}
  ${Media.phone`
     width:100%;
     height: 11.26rem;
     margin-bottom: 0.6rem;
    .question{
      background: url(${questionPhone}) no-repeat;
      top: 1rem;
      left: 3.04rem;
    }
    .shop {
      position: absolute;
      right: -0.08rem;
      top: 2rem;
      background: url(${shopPhone}) no-repeat;
      width: 3.18rem;
      height: 3.74rem;
    }
    .previous,
    .next{
      width: 2.2rem;
      height: 0.6rem;
      bottom: 1.32rem;
      box-sizing: border-box;
    }
    .previous {
      right: 4.2rem;
      img {
        margin-right: 0.2rem;
      }
    }
    .next {
      right: 1.16rem;
      img {
        margin-left: 0.2rem;
      }
    }
    .pay{
      left: 3.52rem; 
      top: 0.92rem;
      background: url(${payPhone}) no-repeat;
    }
  `}
`

export const CardLabel = styled.div`
  font-family: Monda;
  top: 42px;
  left: 16px;
  background: linear-gradient(
    270deg,
    rgba(82, 169, 243, 0.8) 0%,
    rgba(79, 178, 255, 0.8) 56.68%,
    rgba(70, 98, 255, 0.8) 100%
  );
  border-radius: 30px 0 30px 0;
  position: absolute;
  width: 504px;
  height: 178px;

  p {
    width: 133px;
    height: 27px;
    background: url(${part1}) center / 100% 100% no-repeat;
    margin: 40px 0 0 142px;
  }
  ${Media.ipad`
    width: 376px;
    height: 172px;
    top: 34px;
    left: 18px;
    p{
      width: 96px;
      height: 20px;
      background: url(${part1Ipad}) center / 100% 100% no-repeat;
      margin: 34px 0 0 104px;
    }
  `}
  ${Media.phone`
    width: 2.56rem;
    height: 1.4rem;
    top: 0.44rem;
    left: 0.36rem;
    border-radius: 0.32rem 0 0.32rem 0;
    p{
      width: 1.18rem;
      height: 0.26rem;
      background: url(${part1Phone}) center / 100% 100% no-repeat;
      margin: 0.26rem 0 0 0.6rem;
    }
  `}
`

export const CardTContent = styled.div`
  width: 100%;
  height: 100%;
  background: url(${cardBg}) center/ 100% 100% no-repeat;
  z-index: 1;
  position: absolute;
  p {
    font-size: 40px;
    font-weight: 700;
    color: transparent;
    background: linear-gradient(290.07deg, #445fff 53.77%, #55bcee 97.08%);
    -webkit-background-clip: text;
    background-clip: text;
    width: 662px;
    text-align: center;
    position: absolute;
    right: 68px;
    top: 40px;
  }
  ${Media.ipad`
    background: url(${cardBgIpad}) center/ 100% 100% no-repeat;
    p {
      width:515px;
      font-size: 30px;
      top: 42px;
      right: 52px;
    }
  `}
  ${Media.phone`
    background: url(${cardBgPhone}) center/ 100% 100% no-repeat;
    p{
      width: 4rem;
      height: 1rem;
      font-size: 0.4rem;
      top:0.4rem;
      right: 0.4rem;
    }
  `}
`

export const QA = styled.div`
  position: absolute;
  top: 320px;
  z-index: 3;
  p {
    color: #1f2329;
    font-size: 20px;
    margin-bottom: 24px;
  }
  .error {
    border-color: rgb(245, 74, 69);
  }
  ${Media.ipad`
    top: 280px
  `}
  ${Media.phone`
      width:100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 2.82rem;
      p {
        width: 6rem;
        font-size: 0.32rem;
        text-align: center;
        height: 0.8rem
      }
  `}
`
export const ButtonText = styled(Button)``

export const CampaginTextarea = styled.textarea`
  width: 974px;
  height: 142px;
  padding: 20px;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
  background: none;
  resize: none;
  overflow: hidden;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  border: 1px solid #c8d5e8;
  border-radius: 10px;
  background-color: #fff;
  ::placeholder {
    font-size: 20px;
    color: #a5abbb;
    font-weight: 400;
  }
  ${Media.ipad`
    width: 739px;
    height: 142px;
  `}
  ${Media.phone`
      width:6.46rem;
      height:4.86rem;
      ::placeholder {
        font-size: 0.28rem;
      }
  `}
`
