import { Button } from '@raylink-overseas/common/components'
import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #5f5f5f;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`

const NoticeWrapper = styled.div`
  width: 900px;
  height: 480px;
  padding: 60px;
  border-radius: 30px;
  background: linear-gradient(180deg, #e4e7ff 0%, #ffffff 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  p {
    font-size: 28px;
    margin-bottom: 30px;
    text-align: center;
    width: 592px;
  }
  button {
    width: 338px;
    height: 80px;
  }
`

const NoticeModal = () => {
  return (
    <ModalWrapper>
      <NoticeWrapper>
        <h2>Notice</h2>
        <p>Our campaign has ended. Thank you for your interest in Avica.</p>
        <Button
          onClick={() => {
            navigate('/')
          }}
        >
          Back to Homepage
        </Button>
      </NoticeWrapper>
    </ModalWrapper>
  )
}

export default NoticeModal
