import React, { useState } from 'react'
import { Layout } from '@raylink-overseas/common/components'
import { PromoCampaginWrapper, RunningMan, RunningManWrapper } from './atoms'
import CountDown from './components/countdown'
import Question, { questionsInfomatin } from './components/question'
import Pay from './components/pay'
import Email from './components/email'
import Conditions from './components/conditions'
import Anchor from './components/anchor'
import NoticeModal from './components/noticeModal'
import volleyball from '../../images/promo-compagin/volleyball.svg'

const PromoCampagin = () => {
  const [showAnchor, setShowAnchor] = useState(false)
  const [activeAnchor, setActiveAnchor] = useState('#q&a')
  const [showNoticeModal] = useState(true)

  const [questionsInfo, setQuestionsInfo] = useState(questionsInfomatin)

  return (
    <Layout pageType="campagin">
      {showNoticeModal ? (
        <NoticeModal />
      ) : (
        <>
          <PromoCampaginWrapper>
            <CountDown />
            <RunningManWrapper>
              <RunningMan />
            </RunningManWrapper>
            <Question
              setShowAnchor={setShowAnchor}
              setActiveAnchor={setActiveAnchor}
              questionsInfo={questionsInfo}
              setQuestionsInfo={setQuestionsInfo}
            />
            <Pay isSubmit={questionsInfo.isSubmit} />
            <Email />
            <Conditions />
            <img src={volleyball} className="volleyball" />
          </PromoCampaginWrapper>
          {showAnchor && <Anchor activeAnchor={activeAnchor} />}
        </>
      )}
    </Layout>
  )
}

export default PromoCampagin
