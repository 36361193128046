import styled from 'styled-components'
import connectBetterUnderline from '../../../../images/promo-compagin/connect-better_underline.svg'
import ConnetBetter from '../../../../images/promo-compagin/connect-better.svg'
import ConnetBetterPhone from '../../../../images/promo-compagin/connect-better_phone.svg'
import connectBetterBottom from '../../../../images/promo-compagin/connect-better_bottom.svg'
import arrowDown from '../../../../images/promo-compagin/arrow-down.svg'
import connectBetterBottomPhone from '../../../../images/promo-compagin/connect-better-bottom_phone.svg'
import arrowDownPhone from '../../../../images/promo-compagin/arrow-down_phone.svg'
import arrowDownWhite from '../../../../images/promo-compagin/arrow-dowm_white.svg'
import connectLogo from '../../../../images/promo-compagin/connect-logo.svg'
import connectLogoIpad from '../../../../images/promo-compagin/connect-logo_ipad.svg'
import connectLogoPhone from '../../../../images/promo-compagin/connect-logo_phone.svg'
import amazon from '../../../../images/promo-compagin/amazon.png'
import amazonIpad from '../../../../images/promo-compagin/amazon_ipad.png'
import amazonPhone from '../../../../images/promo-compagin/amazon_phone.png'
import license from '../../../../images/promo-compagin/license.svg'
import licensePhone from '../../../../images/promo-compagin/license_phone.svg'
import { Media } from '@raylink-overseas/common/theme'

export const CountDownWrapper = styled.div`
  width: 780px;
  height: 400px;
  margin: 191px 0 225px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
  .connect-better {
    position: absolute;
    top: -20px;
    width: 342px;
    height: 60px;
    background: url(${ConnetBetter}) no-repeat;
    text-align: center;
    line-height: 60px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    z-index: 2;
  }
  .amazon {
    position: absolute;
    right: -20px;
    top: -42px;
    width: 127px;
    height: 129px;
    background: url(${amazon}) no-repeat;
  }
  .connect-logo {
    position: absolute;
    top: -100px;
    width: 573px;
    height: 444px;
    background: url(${connectLogo}) no-repeat;
  }
  .license {
    position: absolute;
    left: -42px;
    bottom: -38px;
    width: 144px;
    height: 141px;
    z-index: 1;
    background: url(${license}) no-repeat;
  }
  .connect-better-bottom {
    background: url(${connectBetterBottom}) no-repeat;
    position: absolute;
    bottom: -22px;
    left: -10px;
    width: 820px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    .arrow-down {
      background: url(${arrowDown}) no-repeat;
      cursor: pointer;
      width: 180px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .arrow-down_white_one,
      .arrow-down_white_two {
        width: 22px;
        height: 12px;
        background: url(${arrowDownWhite}) center / 100% 100% no-repeat;
      }
      @keyframes opacityOne {
        from {
          opacity: 0;
          transform: scale(0.6);
        }
        50% {
          opacity: 1;
          transform: scale(1);
        }
        to {
          opacity: 0;
          transform: scale(0.6);
        }
      }
      @keyframes opacityTwo {
        from {
          opacity: 1;
          transform: scale(1);
        }
        50% {
          opacity: 0;
          transform: scale(0.6);
        }
        to {
          opacity: 1;
          transform: scale(1);
        }
      }
      .arrow-down_white_one {
        animation: opacityOne 2s ease-in-out 1.2s infinite;
      }
      .arrow-down_white_two {
        animation: opacityTwo 2s ease-in-out 1.2s infinite;
      }
    }
  }
  ${Media.ipad`
    margin: 244px 0 80px;
    .connect-logo{
      width: 588px;
      height: 445px;
      background: url(${connectLogoIpad}) no-repeat;
    }
    .amazon {
      width: 152px;
      height: 155px;
      top: -60px;
      right: -50px;
      background: url(${amazonIpad}) no-repeat;
    }
  `}
  ${Media.phone`
    width:100%;
    margin:0 0 1.3rem;
    height: 9.24rem;
    border-radius: 0;
    backdrop-filter: blur(0);
    .amazon{
      background: url(${amazonPhone}) no-repeat;
      width: 1.52rem;
      height: 1.52rem;
      top: 0.5rem;
      right: -0.08rem;
    }
    .license{
      background: url(${licensePhone}) no-repeat;
      bottom:0.6rem;
      left: -0.08rem;
    }
    .connect-logo{
      width: 5rem;
      height: 3.88rem;
      background: url(${connectLogoPhone}) no-repeat;
      top:0.4rem;
    }
    .connect-better{
      width:4.64rem;
      height: 0.76rem;
      top: 1.28rem;
      background: url(${ConnetBetterPhone}) no-repeat center;
      font-size: 16px;
      font-weight: 500;
      line-height: 0.76rem;
    }
    .connect-better-bottom {
      background: url(${connectBetterBottomPhone}) center no-repeat;
      width: 100%;
      height: 0.6rem;
      bottom: -0.6rem;
      left:0;
      .arrow-down {
        background: url(${arrowDownPhone}) no-repeat;
        width: 128px;
        height: 30px;
        .arrow-down_white_one,
        .arrow-down_white_two {
          width: 0.32rem;
          height: 0.24rem;
        }
      }
    }
  `}
`
export const CountDownTitle = styled.h1`
  font-size: 40px;
  text-align: center;
  z-index: 2;
  background: linear-gradient(
    90.48deg,
    #445fff 0.15%,
    #475fee 53.89%,
    #000000 99.24%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin: 70px 0 38px;
  ::after {
    position: absolute;
    right: 120px;
    width: 420px;
    height: 18px;
    display: block;
    content: ' ';
    background: url(${connectBetterUnderline}) center / 100% 100% no-repeat;
  }
  ${Media.ipad`
      font-size: 36px;
  `}
  ${Media.phone`
    ::after {
      display: none;
    }
    font-size: 0.52rem;
    margin: 2.64rem 0 0.24rem;
    width:6.86rem
  `}
`
export const CountDownDescription = styled.p`
  font-size: 16px;
  text-align: center;
  color: #363636;
  width: 660px;
  margin-bottom: 20px;
  z-index: 2;
  ${Media.phone`
    width: 6.86rem;
    font-size: 0.28rem;
    margin-bottom: 0.48rem;
  `}
`

export const CountDownTimeEnd = styled(CountDownDescription)`
  margin-bottom: 12px;
  ${Media.phone`
      margin-bottom:0.28rem
  `}
`

export const CountDownTimeWrapper = styled.div`
  width: 316px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
`
export const CountDownTimeItem = styled.div`
  div {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px #0e1fa033 inset;

    p {
      background: #1583fe;
      margin: 2px;
      width: 46px;
      height: 46px;
      border-radius: 10px;
      font-size: 24px;
      font-weight: 700;
      color: #fff;
      line-height: 46px;
      text-align: center;
    }
  }

  p {
    font-size: 14px;
    color: #363636;
    margin-top: 10px;
    text-align: center;
  }
  ${Media.phone`
    div {
      background: none;
      box-shadow: none;
    }
  `}
`
