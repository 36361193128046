import React from 'react'
import { StepsItem, StepsWrapper } from './atom'

type Props = {
  questionsInfo: {
    questions: Array<{
      question: string
      answer: string
    }>
    currentStep: number
  }
}

const Steps: React.FC<Props> = props => {
  const { questionsInfo } = props
  return (
    <StepsWrapper>
      {questionsInfo.questions.map((step, index) => (
        <StepsItem key={index}>
          <div className={index === questionsInfo.currentStep ? 'active' : ''}>
            Q{index + 1}
          </div>
          {index < questionsInfo.questions.length - 1 && (
            <div className="arrow" />
          )}
        </StepsItem>
      ))}
    </StepsWrapper>
  )
}

export default Steps
