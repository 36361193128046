import { Media } from '@raylink-overseas/common/theme'
import styled from 'styled-components'
import blueLine from '../../../../images/promo-compagin/blue-line.svg'
import greyLine from '../../../../images/promo-compagin/grey-line.svg'
import blueLinePhone from '../../../../images/promo-compagin/blue-line_phone.svg'
import greyLinePhone from '../../../../images/promo-compagin/grey-line_phone.svg'

export const StepsWrapper = styled.div`
  display: flex;
  top: 200px;
  left: 117px;
  z-index: 3;
  position: absolute;
  ${Media.ipad`
    top: 170px;
    left: 90px;
  `}
  ${Media.phone`
    top: 1.86rem;
    left: 0.7rem;
  `}
`

export const StepsItem = styled.div`
  display: flex;
  align-items: center;
  div {
    width: 80px;
    height: 80px;
    border-radius: 60px;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    line-height: 80px;
    background: linear-gradient(290.07deg, #cacaca 53.77%, #ebebeb 102.07%);
    box-shadow: 2px 4px 10px 0px #ffffff33 inset;
  }
  div.active {
    background: linear-gradient(290.07deg, #445fff 53.77%, #55bcee 102.07%);
    box-shadow: 2px 4px 10px 0px #ffffff33 inset;
  }
  .arrow {
    width: 205px;
    height: 30px;
    background: url(${greyLine}) center/ 100% 100% no-repeat;
    margin: 0 20px;
  }
  .active .arrow {
    background: url(${blueLine}) center/ 100% 100% no-repeat;
  }
  ${Media.phone`
      div{
        width: 0.8rem;
        height: 0.8rem;
        font-size: 0.28rem;
        line-height: 0.8rem;
      }
      .arrow {
        width: 1.16rem;
        height: 0.4rem;
        background: url(${greyLinePhone}) center/ 100% 100% no-repeat;
        margin: 0 0.36rem;
      }
      .active .arrow {
        background: url(${blueLinePhone}) center/ 100% 100% no-repeat;
      }
  `}
`
