import React, { useEffect, useState } from 'react'
import CreditCard from './credit-card'
import payIcon from '../../../../images/promo-compagin/pay-icon.svg'
import CircleTick from '../../../../images/pricing/circle-tick.svg'
import { Button } from '@raylink-overseas/common/components'
import { FloatDialog } from '../../../pricing/components/float-dialog'
import isTest from '@raylink-overseas/common/utils/isTest'
import { API, Axios } from '@raylink-overseas/common/api'
import {
  PayCardLabel,
  PayCardTContent,
  PayCardWrapper,
  PayContentLeft,
  PayContentRight,
  PayContentWrapper,
  PayListWrapper,
} from './atom'

type Props = {
  isSubmit: boolean
}

export type ProductInfo = Array<{
  id: number
  activityId: string
  activityName: string
  isBasicActivity: boolean
  startTime: string
  endTime: string
  activityImageVOList: Array<{
    activityId: number
    osType: number
    imageHeight: number
    imageWidth: number
    image: string
    jumpLink: string
  }>
}>

const avicaProInfo = [
  '1 Concurrent connection (channel)',
  '5 Concurrent sessions per channel',
  'Ultra HD image quality',
  'Up to 144 FPS ',
  '4:4:4 Color mode',
  'Mobile device support',
  'Secure file transfer',
  'Multi-to-multi monitor',
]

const Pay: React.FC<Props> = props => {
  const { isSubmit } = props

  const http = new Axios(API.host)

  const baiseLink = `https://console${isTest() ? '-pre' : ''}.avica.link/pay`

  const [buyLink, setBuyLink] = useState(baiseLink)

  useEffect(() => {
    http.get<ProductInfo>(API.getMemberProductInfo).then(res => {
      if (
        res?.code === 200 &&
        Array.isArray(res?.data) &&
        res.data.length > 0
      ) {
        setBuyLink(`${baiseLink}?activityId=${res?.data[0]?.activityId}`)
      }
    })
  }, [])

  return (
    <PayCardWrapper id="pay">
      <PayCardLabel>
        <p />
      </PayCardLabel>
      <PayCardTContent>
        <p>Get 15% OFF for Avica Pro</p>
      </PayCardTContent>

      <div className="pay" />
      <PayContentWrapper>
        <PayContentLeft>
          <img src={payIcon} alt="" srcSet="" />
          <h2> Avica Pro</h2>
          <p>Secure, simple and seamless remote desktop software</p>
        </PayContentLeft>
        <div className="middle">
          <PayListWrapper>
            {avicaProInfo.map((info, idx) => (
              <li key={idx}>
                <img className="icon" src={CircleTick} alt="" />
                {info}
              </li>
            ))}
          </PayListWrapper>
        </div>
        <PayContentRight>
          <p>Yearly Plan</p>
          <p>
            <span>$</span>
            <span>42.50</span>
            <span>$50.00</span>
          </p>
          <div className="button-area">
            <div className="buy-now">
              {!isSubmit && (
                <FloatDialog className="tip">
                  Unlock the 15% discount by taking part in the Q&A game. Act
                  now and <a href="#q&a">join in</a>.
                </FloatDialog>
              )}
              <Button
                disabled={!isSubmit}
                onClick={() => {
                  window.open(buyLink, '_blank')
                }}
              >
                Buy Now
              </Button>
            </div>
            <Button
              onClick={() => {
                window.open('/downloads/', '_blank')
              }}
            >
              Free Download
            </Button>
          </div>
          <p>* 100% Secure Transaction</p>
        </PayContentRight>
      </PayContentWrapper>
      <CreditCard />
    </PayCardWrapper>
  )
}

export default Pay
