import { color, Media } from '@raylink-overseas/common/theme'
import payCard from '../../../../images/promo-compagin/pay-bg.png'
import payCardIpad from '../../../../images/promo-compagin/pay-bg_ipad.png'
import payCardPhone from '../../../../images/promo-compagin/pay-bg_phone.png'
import { ListWrapper } from '../../../pricing/components/price-card/atoms'
import { CardLabel, CardTContent, CardWrapper } from '../question/atom'
import styled from 'styled-components'
import part2 from '../../../../images/promo-compagin/part2.svg'
import part2Ipad from '../../../../images/promo-compagin/part2_ipad.svg'
import part2Phone from '../../../../images/promo-compagin/part2_phone.svg'

export const PayContentWrapper = styled.div`
  margin: 0 50px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 2;
  width: 1100px;
  top: 200px;
  ${Media.ipad`
      width: 739px;
      flex-wrap: wrap;
      top: 155px;
  `}
  ${Media.phone`
    flex-direction: column;
    width: 100%;
    top: 2rem;
    align-items: center;
    .middle{
      width: 6.06rem;
      ul li{
        font-size: 0.32rem;
        margin-bottom: 0;
      }
      margin-bottom: 0.4rem;
    }
  `}
`
export const PayContentLeft = styled.div`
  width: 235px;
  color: #1f2329;
  h2 {
    font-size: 28px;
    font-weight: 700;
  }
  p {
    font-size: 16px;
  }
  ${Media.ipad`
    flex: 1 1 100%;
      display: flex;
      align-items: center;
      h2{
        width:135px;
        text-align: center;
        margin-right: 40px;
      }
      p{
        width: 255px;
       }
      margin-bottom: 24px;
  `}
  ${Media.phone`
    width:6.06rem;
      height: 2.68rem;
      flex-wrap: wrap;
      img {
        margin-right: 0.16rem;
      }
      p {
        width: 100%;
        height: 1.28rem;
        padding: 0.2rem;
      }
      margin-bottom: 0.2rem;
  `}
`

export const PayContentRight = styled.div`
  padding: 36px;
  width: 425px;
  height: 266px;
  border: 1px solid #445fff;
  border-radius: 10px;
  background: #fff;
  p:nth-of-type(1) {
    font-size: 24px;
    font-weight: 500;
    color: #646a73;
    margin-bottom: 10px;
  }
  p:nth-of-type(2) {
    margin-bottom: 30px;
    span:nth-of-type(1) {
      font-size: 20px;
    }
    span:nth-of-type(2) {
      font-size: 36px;
    }
    span:nth-of-type(1),
    span:nth-of-type(2) {
      font-weight: 700;
      color: ${color.primary};
    }
    span:nth-of-type(3) {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 500;
      color: #646a73;
      text-decoration: line-through;
    }
  }
  .button-area {
    margin-bottom: 10px;
    display: flex;
    button {
      width: 166px;
      height: 44px;
      font-weight: 400;
    }
    button:nth-of-type(1) {
      margin-right: 16px;
    }
    .tip {
      width: 166px;
      position: absolute;
      top: 46px;
      opacity: 0;
      transition: all 0s 0.1s;
    }
    .buy-now:hover {
      .tip {
        opacity: 1;
      }
    }
  }
  p:nth-of-type(3) {
    color: #a6b0be;
    font-size: 16px;
  }
  ${Media.ipad`
    width: 318px;
    height: 318px;
    padding: 38px;
    .button-area {
        flex-direction: column;
        button:nth-of-type(1) {
          margin-bottom: 10px;
        }
      }
    .tip{
      top: 140px !important;
    }
  `}
  ${Media.phone`
    width: 6.06rem;
    height: 5.96rem;
    padding: 0.6rem;
    .tip{
        top: 9.8rem !important;
    }
    p:nth-of-type(1) {
      font-size: 0.4rem;
    }
    .button-area {
      button {
        width: 100%
      }
    }
  `}
`

export const PayCardLabel = styled(CardLabel)`
  p {
    background: url(${part2}) center / 100% 100% no-repeat;
  }
  ${Media.ipad`
    width: 388px;
    height: 132px;
    top: 38px;
    left: 18px;
    p {
      background: url(${part2Ipad}) center / 100% 100% no-repeat;
    }
  `}
  ${Media.phone`
      width: 2.56rem;
      height: 1.4rem;
      top: 0.4rem;
      left: 0.32rem;
      p {
        background: url(${part2Phone}) center / 100% 100% no-repeat;
      }
  `}
`

export const PayCardTContent = styled(CardTContent)`
  background: url(${payCard}) center / 100% 100% no-repeat;
  ${Media.ipad`
    background: url(${payCardIpad}) center  / 100% 100% no-repeat;
    p{
      top: 44px;
    }
  `}
  ${Media.phone`
      background: url(${payCardPhone}) center  / 100% 100% no-repeat;
      p{
        width: 3.6rem;
        height: 1rem;
        top:0.36rem;
        right: 0.6rem;
      }
  `}
`

export const PayCardWrapper = styled(CardWrapper)`
  ${Media.ipad`
    height: 748px;
  `}
  ${Media.phone`
      height:19.28rem
  `}
`

export const PayListWrapper = styled(ListWrapper)`
  li {
    font-size: 20px;
  }
  ${Media.ipad`
    li {
      margin-bottom: 12px
    }
  `}
`
