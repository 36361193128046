import { Media } from '@raylink-overseas/common/theme'
import styled from 'styled-components'
import facebook from '../../../../images/promo-compagin/facebook.svg'
import linkedin from '../../../../images/promo-compagin/linkedin.svg'
import discord from '../../../../images/promo-compagin/discord.svg'
import emailCard from '../../../../images/promo-compagin/email-bg.png'
import emailCardIpad from '../../../../images/promo-compagin/email-bg_ipad.png'
import emailCardPhone from '../../../../images/promo-compagin/email-bg_phone.png'
import facebookGradation from '../../../../images/promo-compagin/facebook_gradation.svg'
import linkedinGradation from '../../../../images/promo-compagin/linkedin_gradation.svg'
import discordGradation from '../../../../images/promo-compagin/discord_gradation.svg'
import tip from '../../../../images/promo-compagin/tip.svg'
import tipPhone from '../../../../images/promo-compagin/tip_phone.svg'
import { CardLabel, CardTContent, CardWrapper } from '../question/atom'
import part3 from '../../../../images/promo-compagin/part3.svg'
import part3Ipad from '../../../../images/promo-compagin/part3_ipad.svg'
import part3Phone from '../../../../images/promo-compagin/part3_phone.svg'

export const EamilCardWrapper = styled(CardWrapper)`
  ${Media.ipad`
    
  `}
  ${Media.phone`
   
  `}
`

export const EmailCardLabel = styled(CardLabel)`
  p {
    background: url(${part3}) center / 100% 100% no-repeat;
  }
  ${Media.ipad`
    p {
      background: url(${part3Ipad}) center / 100% 100% no-repeat;
    }
  `}
  ${Media.phone`
    p {
      background: url(${part3Phone}) center / 100% 100% no-repeat;
    }
  `}
`

export const EmailCardTContent = styled(CardTContent)`
  background: url(${emailCard}) no-repeat;
  ${Media.ipad`
    background: url(${emailCardIpad}) no-repeat center  / 100% 100%;
  `}
  ${Media.phone`
    background: url(${emailCardPhone}) no-repeat center  / 100% 100%;
  `}
`

export const EmailContentWrapper = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 203px;
  .description {
    width: 889px;
    text-align: center;
    font-size: 20px;
    margin-bottom: 46px;
    span {
      font-weight: 700;
    }
  }
  .email {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
    textarea {
      width: 646px;
      height: 60px;
      padding: 20px;
      margin-right: 16px;
    }
    .tip-wrapper {
      width: 27px;
      height: 27px;
      background: url(${tip}) no-repeat;
      .tip {
        position: absolute;
        top: 56px;
        right: 30px;
        width: 167px;
        opacity: 0;
        transition: all 0s 0.1s;
      }
    }
    .tip-wrapper:hover .tip {
      opacity: 1;
    }
  }
  .confirm {
    margin-bottom: 42px;
    display: flex;
    align-items: center;
    span {
      font-size: 20px;
      color: #666666;
      margin-left: 12px;
    }
    .checkbox {
      vertical-align: middle;
      visibility: hidden;
      position: relative;
      cursor: pointer;
    }

    .checkbox:checked:after {
      visibility: visible;
    }
    .checkbox:checked::before {
      background: #1677ff;
    }

    .checkbox::before {
      display: block;
      visibility: visible;
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 2px;
      left: 0px;
      top: -6px;
      background-color: #fff;
    }

    .checkbox::after {
      display: block;
      visibility: hidden;
      content: '';
      width: 6px;
      height: 10px;
      position: absolute;
      left: 6px;
      top: -4px;
      border-right: 3px solid #fff;
      border-bottom: 3px solid #fff;
      transform: rotate(45deg);
    }
  }
  .enter {
    width: 338px;
    height: 60px;
    margin-bottom: 50px;
    font-weight: 400;
  }
  .link {
    display: flex;
    p {
      font-size: 20px;
      color: #666666;
      margin-right: 10px;
    }
    .img-warpper {
      width: 92px;
      display: flex;
      justify-content: space-between;
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
      }
      .facebook {
        background: url(${facebook}) no-repeat;
      }
      .linkedin {
        background: url(${linkedin}) no-repeat;
      }
      .discord {
        background: url(${discord}) no-repeat;
      }
      .facebook:hover {
        background: url(${facebookGradation}) no-repeat;
      }
      .linkedin:hover {
        background: url(${linkedinGradation}) no-repeat;
      }
      .discord:hover {
        background: url(${discordGradation}) no-repeat;
      }
    }
  }
  ${Media.ipad`
    top: 146px;
    .description{
      font-size:20px;
      width:717px;
      margin-bottom: 40px;
      .email-address{
        display: inline-block;
      }
    }
    .email,.confirm {
        margin-bottom: 20px;
    }
    .enter {
        margin-bottom: 40px;
    }
    .email{
       .tip-wrapper {
       .tip {
            top: 51px;
            right: -56px;
       }
    }
  `}
  ${Media.phone`
      top: 2.32rem;
      width: 100%;
      height: 11.26rem;
      .description {
        width: 6rem;
        font-size: 0.32rem;
        margin-bottom: 0.6rem;
      }
      .email{
        width: 5.28rem;
        height: 0.8rem;
        .tip-wrapper {
          background: url(${tipPhone}) no-repeat;
          width: 0.36rem;
          height: 0.36rem;
          .tip { 
            top: 1.28rem;
            right: 0.08rem;
          }
        }
        textarea {
          width: 4.28rem;
          height: 0.8rem;
          padding: 0.22rem;
          margin-right: 0.16rem;
          border-radius: 0.12rem
        }
      }
      .confirm{
        width: 5.34rem;
        margin-bottom: 0.48rem;
        .checkbox{
          top: -0.08rem;
        }
        .checkbox::before {
          width: 0.28rem;
          height: 0.28rem;
          border-radius: 0.02rem;
        }
        .checkbox::after{
          width: 0.08rem;
          height: 0.16rem;
          top: -0.12rem;
          left: 0.08rem;
        }
        span {
          font-size: 0.28rem;
          margin-left: 0.2rem;
        }
      }
      .enter{
        margin-bottom: 0.56rem;
        width: 4.52rem;
        height: 0.88rem;
      }
      .link {
        width: 4.76rem;
        height: 1rem;
        flex-direction: column;
        align-items: center;
        p {
          width: 100%;
          font-size: 0.32rem;
          margin-bottom: 0.2rem;
        }
        .img-warpper{
          align-items: center;
          a span{
            height: 0.4rem;
          }
        }
      }
  `}
`
