import React, { useEffect, useRef, useState } from 'react'
import CryptoJS from 'crypto-js'
import {
  CardWrapper,
  CardLabel,
  CardTContent,
  QA,
  ButtonText,
  CampaginTextarea,
} from './atom'

import arrowLeft from '../../../../images/promo-compagin/arrow-left.svg'
import arrowRight from '../../../../images/promo-compagin/arrow-right.svg'
import Steps from '../step'
import Button from '@raylink-overseas/common/components/button'
import { anchorList } from '../anchor'
import Submitted from '../submitted'
import { navigate } from 'gatsby'
import { API, Axios } from '@raylink-overseas/common/api'

type Props = {
  setShowAnchor: React.Dispatch<React.SetStateAction<boolean>>
  setActiveAnchor: React.Dispatch<React.SetStateAction<string>>
  questionsInfo: typeof questionsInfomatin
  setQuestionsInfo: React.Dispatch<
    React.SetStateAction<typeof questionsInfomatin>
  >
}

export const questionsInfomatin = {
  currentStep: 0,
  isSubmit: false,
  questions: [
    {
      question:
        'Q1:  Which country are you cheering for in the Paris 2024 Olympics?',
      placeholdText: 'Please enter your answer here.',
      answer: '',
      errorState: false,
    },
    {
      question: 'Q2:   What is your occupation?',
      placeholdText:
        'Please enter your answer here, for example: Graphic Designer.',
      answer: '',
      errorState: false,
    },
    {
      question: 'Q3:    How do you primarily use Avica Remote Desktop?',
      placeholdText:
        'Please enter your answer here, for example: I do graphic design tasks remotely using my personal computer at home.',
      answer: '',
      errorState: false,
    },
  ],
}

const Question: React.FC<Props> = props => {
  const { setShowAnchor, setActiveAnchor, questionsInfo, setQuestionsInfo } =
    props

  const http = new Axios(API.host)

  const [showSubmit, setShowSubmit] = useState(false)

  const qaRef = useRef<HTMLDivElement>(null)

  const isInViewport = (element: HTMLElement | null) => {
    const rect = element?.getBoundingClientRect()
    return rect ? rect.top <= rect.height : false
  }

  useEffect(() => {
    const checkScrollable = () => {
      const element = qaRef.current
      if (element) {
        const rect = element.getBoundingClientRect()
        setShowAnchor(rect?.top <= 68)
      }
      anchorList.forEach(anchor => {
        const element = document.getElementById(anchor.id)
        if (isInViewport(element)) {
          setActiveAnchor(anchor.id)
        }
      })
    }

    window.addEventListener('scroll', checkScrollable)

    const questionsInfo = localStorage.getItem('questionsInfo')

    if (questionsInfo) {
      setQuestionsInfo(JSON.parse(questionsInfo))
    }

    return () => {
      window.removeEventListener('scroll', checkScrollable)
    }
  }, [])

  const handleNext = () => {
    if (questionsInfo.currentStep < questionsInfo.questions.length - 1) {
      setQuestionsInfo({
        ...questionsInfo,
        currentStep: questionsInfo.currentStep + 1,
      })
    } else {
      const answers = questionsInfo.questions.map(question => question.answer)

      if (answers.includes('')) {
        const firstEmptyIndex = answers.findIndex(el => el === '')
        setQuestionsInfo({
          ...questionsInfo,
          currentStep: firstEmptyIndex,
          questions: questionsInfo.questions.map(question => ({
            ...question,
            errorState: question.answer === '',
          })),
        })
      } else {
        const md5Info = `productUsage=${questionsInfo.questions[2].answer}&profession=${questionsInfo.questions[1].answer}&supportCountry=${questionsInfo.questions[0].answer}&raylink6`

        const params = {
          supportCountry: questionsInfo.questions[0].answer,
          profession: questionsInfo.questions[1].answer,
          productUsage: questionsInfo.questions[2].answer,
          md5: CryptoJS.MD5(md5Info).toString(),
        }
        http.post(API.collectUserInfo, params).then(res => {
          if (res?.code === 200) {
            setShowSubmit(true)
            const copyQuestionsInfo = { ...questionsInfo, isSubmit: true }
            setQuestionsInfo(copyQuestionsInfo)
            localStorage.setItem(
              'questionsInfo',
              JSON.stringify(copyQuestionsInfo)
            )
          }
        })
      }
    }
  }

  return (
    <CardWrapper id="q&a" ref={qaRef}>
      <CardLabel>
        <p />
      </CardLabel>
      <CardTContent>
        <p>Answer Questions to Unlock Offer</p>
      </CardTContent>
      <Steps questionsInfo={questionsInfo} />
      <QA>
        <p>{questionsInfo.questions[questionsInfo.currentStep].question}</p>
        <CampaginTextarea
          className={
            questionsInfo.questions[questionsInfo.currentStep].errorState
              ? 'error'
              : ''
          }
          placeholder={
            questionsInfo.questions[questionsInfo.currentStep].placeholdText
          }
          value={questionsInfo.questions[questionsInfo.currentStep].answer}
          onChange={e => {
            const updatedQuestions = [...questionsInfo.questions]
            updatedQuestions[questionsInfo.currentStep].answer = e.target.value
            questionsInfo.questions[questionsInfo.currentStep].errorState =
              updatedQuestions[questionsInfo.currentStep].answer === ''
            setQuestionsInfo({ ...questionsInfo, questions: updatedQuestions })
          }}
        />
      </QA>
      <div className="question" />
      {questionsInfo.currentStep > 0 && (
        <ButtonText
          className="previous"
          onClick={() => {
            if (questionsInfo.currentStep > 0) {
              setQuestionsInfo({
                ...questionsInfo,
                currentStep: questionsInfo.currentStep - 1,
              })
            }
          }}
        >
          <img src={arrowLeft} alt="" /> <span>Previous</span>
        </ButtonText>
      )}
      <Button className="next" onClick={handleNext}>
        {questionsInfo.currentStep === questionsInfo.questions.length - 1 ? (
          'Submit'
        ) : (
          <>
            <span>Next</span> <img src={arrowRight} alt="" />
          </>
        )}
      </Button>
      {showSubmit && (
        <Submitted
          description={`Congratulations! You've won a 15% discount for Avica Pro. Purchase now for an exceptional remote desktop experience.`}
          buttonText="Get Offer Now"
          clickCallback={() => {
            navigate('/promo-campaign.html#pay')
            setShowSubmit(false)
          }}
        />
      )}
    </CardWrapper>
  )
}

export default Question
