import React from 'react'
import styled from 'styled-components'
import { Button } from '@raylink-overseas/common/components'
import successIcon from '../../../images/promo-compagin/success.svg'
import successIconPhone from '../../../images/promo-compagin/success_phone.svg'
import { Media } from '@raylink-overseas/common/theme'

type Props = {
  description: string
  buttonText: string
  clickCallback: () => void
}

const SubmittedContainer = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 620px;
    height: 326px;
    border-radius: 30px;
    background: linear-gradient(180deg, #e4e7ff 0%, #ffffff 100%);
    position: relative;
    .img {
      width: 100px;
      height: 100px;
      position: absolute;
      top: -50px;
      background: url(${successIcon}) no-repeat;
    }
    h3 {
      font-size: 34px;
      font-weight: 500;
      margin-bottom: 30px;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      width: 500px;
      text-align: center;
      margin-bottom: 30px;
    }
    button {
      width: 286px;
      height: 50px;
    }
  }

  ${Media.phone`
     div{
      width: 6.46rem;
      height: 6.26rem;
      .img{
        top: -0.7rem;
        width: 1.4rem;
        height: 1.4rem;
        background: url(${successIconPhone}) no-repeat;
      }
      h3{
        font-size: 0.68rem;
        margin-bottom: 0.4rem;
      }
      p {
        font-size: 0.32rem;
        font-weight: 400;
        width: 5.5rem;
        margin-bottom: 0.6rem;
      }
      button{
        width: 4.24rem;
        height: 0.96rem;
      }
     }
  `}
`

const Submitted: React.FC<Props> = props => {
  const { description, buttonText, clickCallback } = props
  return (
    <SubmittedContainer>
      <div>
        <div className="img" />
        <h3>Submitted</h3>
        <p>{description}</p>
        <Button
          onClick={() => {
            clickCallback()
          }}
        >
          {buttonText}
        </Button>
      </div>
    </SubmittedContainer>
  )
}

export default Submitted
